.dialogsListItem a {
  text-decoration: none;
  color: var(--adm-color-text);
}

.dialogsListItem a:active {
  opacity: 1;
  --adm-color-background: #eeeeee;
}

.dialogsListItem__wrapper {
  padding: 0 9px;
  --align-items: center;
  --prefix-padding-right: 10px;
}

.dialogsListItem__wrapper .adm-list-item-content-main {
  overflow: hidden;
  padding: 9.75px 0;
}

.dialogsListItemSkeleton {
  --width: 100%;
  --height: 50px;
}

/* .dialogsListItem__wrapper-skeleton {
  padding: 7px 9px;
} */

.dialogsListItem__wrapper-closed {
  opacity: 0.8;
  filter: grayscale(1);
}
