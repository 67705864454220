.DialogsListItemAvatar {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  font-size: 14px;
  color: #fff;
  text-align: center;
  overflow: hidden;
  flex-shrink: 0;
}
