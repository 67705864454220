.wrapper {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding: 7px;
  background-color: #f2f5f7;
}

.user {
  display: flex;
  gap: 12px;
  width: 100%;
  border-radius: 50px;
  overflow: hidden;
  cursor: pointer;
  background-color: var(--adm-color-background);
}

.userInfo {
  width: 100%;
  height: 40px;
  padding-right: 20px;
}

.detailsItem {
  --adm-color-background: #f2f5f7;
}

.line {
  background-color: #c3cfd9;
  height: 2px;
}

.responsibleAvatar {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eeeeee;
  border-radius: 4px;
}
