.message-quote {
  max-width: 100%;
  margin-bottom: 5px;
}

.message-quote__content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 10px;
  border-left: 2px solid #1890ff;
}

.message-quote__attachments {
  display: inline-flex;
}

.message-quote__text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #262626;
}
