.dialog-opened {
  height: var(--app-height, 100vh);
  display: flex;
  flex-direction: column;
}

.dialog-opened-list {
  flex: 1;
}

.dialog-opened-top,
.dialog-opened-bottom {
  flex: 0;
}

.dialog-opened-bottom {
  background-color: #f2f5f7;
}

.dialog-opened-bottom__textarea textarea {
  padding: 10px 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  line-height: 1.2125;
}

.dialog-opened-bottom__textarea textarea::-webkit-scrollbar {
  display: none;
}
