.attachments-list {
  padding: 10px 12px 0;
}

.attachments-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
}

.attachments-list-item__name {
  display: flex;
  align-items: center;
  gap: 10px;
}
