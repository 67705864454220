.dialogsListItemDescription__lastMessage {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.dialogsListItemDescription__lastMessageText {
  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
}

.dialogListItemDescription__lastMessageType {
  font-weight: bold;
}

.dialogsListItemDescription__lastMessageDate {
  margin-left: 5px;
}
