.iframe-wrapper {
  position: relative;
  width: 100%;
}

.iframe-wrapper__content {
  width: 100%;
  border: none;
  overflow: hidden;
}

.iframe-wrapper__preview-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 67.5%);
  z-index: 2;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
}

.iframe-wrapper__content.iframe-wrapper__content--empty,
.iframe-wrapper__preview-overlay.iframe-wrapper__preview-overlay--hidden {
  display: none;
}

.iframe-wrapper__preview-collapse-btn {
  color: var(--adm-color-primary);
}
