.layout {
  height: var(--app-height, 100vh);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.layout-content {
  flex: 1;
}

.layout-tab {
  flex: 0;
  border-top: solid 1px var(--adm-color-border);
}

.layout-tab .adm-badge-wrapper {
  padding: 2px 15px;
  border-radius: 20px;
}

.layout-tab .adm-tab-bar-item-active .adm-badge-wrapper {
  background-color: #1677ff33;
}
