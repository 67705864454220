.dialogsListItemTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.dialogsListItemTitle__text {
  font-size: 14px;
  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dialogsListItemTitle__responsibleAvatar {
  --size: 20px;
}

.dialogsListItemTitle__responsibleIcon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e1e5ec;
  border-radius: 4px;
}
