.dialogsListItemOriginIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialogsListItemOriginIcon-marginRight {
  margin-right: 4px;
}
.dialogsListItemOriginIcon-marginLeft {
  margin-left: 4px;
}

.dialogsListItemOriginIcon__icon {
  object-fit: contain;
}
