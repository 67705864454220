.DialogMessagesListView {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  position: absolute;
}

.DialogMessagesListViewInfiniteScroll {
  padding: 4px 0;
}

.DialogListItemAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  overflow: hidden;
  background-color: #e1e5ec;
}
