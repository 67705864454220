.SignInApp {
  height: var(--app-height, 100vh);
  display: flex;
  flex-direction: column;
}

.SignInTitle {
  font-size: 30px;
  margin-bottom: 20px;
}

.SignInDescription {
  font-size: 13px;
  margin-bottom: 20px;
}

.SignInContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* display: grid;
  grid-template-rows: 2.33fr 1fr; */
  padding: 24px 24px 0;
}

/* .SignInForm { */
/* width: -webkit-fill-available; */
/* display: flex;
  flex-direction: column;
  justify-content: end; */
/* } */

.SignInBottom {
  flex: 0;
  padding: 20px 30px;
}

.SignInErr {
  font-size: 15px;
  background-color: #f6dade;
  padding: 12px;
}
